.info-container .content-title {
  color: var(--primary-blue);
  margin-top: 32px;
}

.info-container .content-section {
  margin: 0;
}

.info-container .content-subtitle {
  color: var(--second-text-grey);
}

.message-container {
  margin-top: 0;
  padding: 0;
}

.message-container .patient-msg-item {
  background-color: var(--primary-backgroud-blue);
  margin: 18px 0;
  border-radius: 6px 6px 0 0;
  border: 1px solid var(--primary-blue) !important;
}

.message-container
  .patient-msg-item
  .expand-icon
  .MuiAccordionSummary-expandIcon {
  color: var(--primary-blue);
}

.message-container .patient-msg-item .MuiAccordionSummary-content {
  display: block;
}

.message-container .patient-msg-item .item-title {
  display: flex;
}

.message-container .patient-msg-item .item-title .title {
  width: 50%;
}

.message-container
  .patient-msg-item
  .item-title
  .title.survey-title.title-title,
.message-container
  .patient-msg-item
  .item-title
  .title.survey-title.title-from {
  width: 30%;
}

.message-container .patient-msg-item .item-title .title.survey-title.title-date,
.message-container .patient-msg-item .item-title .title.survey-title.title-id {
  width: 20%;
}

.message-container .patient-msg-item .item-title .title .content {
  color: var(--primary-blue);
  font-weight: bold;
}

.message-container .patient-msg-item .item-content {
  background-color: var(--primary-white);
  padding: 24px 32px;
  border-bottom: 1px solid #00000020;
}

.message-container .patient-msg-item .item-content.appointment {
  padding: 0;
  border-left: 4px solid var(--primary-blue);
}

.message-container
  .patient-msg-item
  .item-content.appointment
  .appointment-list-row {
  border-bottom: 1px solid var(--primary-backgroud-blue);
}

.message-container
  .patient-msg-item
  .item-content.appointment
  .appointement-label {
  padding-left: 32px;
  font-weight: bold;
}

.message-container
  .patient-msg-item
  .item-content.appointment
  .appointment-list-row:last-child,
.message-container
  .patient-msg-item
  .item-content.appointment
  .appointement-label,
.message-container
  .patient-msg-item
  .item-content.appointment
  .appointement-content {
  border-bottom: 0;
}

.message-container .patient-msg-item .item-content .item-content-label {
  color: var(--primary-blue);
  font-weight: bold;
}

.message-container
  .patient-msg-item
  .item-content
  .patient-working-hour
  .line-area {
  display: flex;
  width: 100%;
  padding: 32px 0;
  border-bottom: 1px solid var(--primary-backgroud-blue);
}

.message-container
  .patient-msg-item
  .item-content
  .patient-working-hour
  .line-area:last-child {
  border-bottom: 0;
  padding-bottom: 8px;
}

.message-container
  .patient-msg-item
  .item-content
  .patient-working-hour
  .line-area:first-child {
  padding-top: 8px;
}

.message-container
  .patient-msg-item
  .item-content
  .patient-working-hour
  .working-hour-left {
  background-color: var(--primary-backgroud-blue);
  border-radius: 10px;
  color: var(--primary-blue);
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 250px;
  margin-right: 16px;
  padding: 16px;
}

.message-container
  .patient-msg-item
  .item-content
  .patient-working-hour
  .working-hour-right {
  background-color: var(--primary-backgroud-blue);
  border-radius: 10px;
  width: calc(100% - 266px);
  padding: 20px;
}
.message-container
  .patient-msg-item
  .item-content
  .patient-working-hour
  .working-hour-right
  .button-group {
  display: flex;
  justify-content: space-between;
}

.message-container
  .patient-msg-item
  .item-content
  .patient-working-hour
  .working-hour-right
  .button-group
  .date-btn {
  height: 20px;
  font-size: 12px;
  color: var(--primary-white);
}

.item-content .patient-working-hour .working-hour-right .date-table td {
  border: 0;
  padding: 16px 16px 0 0;
  font-size: 12px;
}
.item-content
  .patient-working-hour
  .working-hour-right
  .date-table
  .date-label {
  color: var(--second-text-grey);
  width: 20%;
}

.MuiGrid-spacing-xs-10 > .MuiGrid-item.recommended-left {
  position: relative;
  padding: 40px 8px 60px;
}

.recommended-left .sign-area {
  position: absolute;
  bottom: 60px;
  display: flex;
  flex-direction: column;
}

.recommended-left .sign-area .sign-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.recommended-left .sign-area .sign-item .sign-icon {
  width: 16px;
  height: 16px;
  display: block;
  border-radius: 50%;
  margin-right: 12px;
  border: 1px solid var(--primary-blue);
  background-color: var(--primary-backgroud-light-blue);
}

.recommended-left .sign-area .sign-item .sign-text {
  display: block;
  height: 20px;
  border-radius: 5px;
  font-size: 10px;
  font-weight: bold;
  padding: 0 12px;
  border: 1px solid var(--primary-blue);
  color: var(--primary-blue);
  background-color: var(--primary-backgroud-light-blue);
  display: flex;
  align-items: center;
}

.recommended-left .sign-area .sign-item .sign-icon.regular-color,
.recommended-left .sign-area .sign-item .sign-text.regular-color {
  border: 1px solid var(--primary-green);
  background-color: var(--primary-backgroud-light-green);
  color: var(--primary-green);
}

.recommended-left .sign-area .sign-item .sign-icon.overlap-color,
.recommended-left .sign-area .sign-item .sign-text.overlap-color {
  border: 1px solid var(--primary-orange);
  background-color: var(--primary-backgroud-light-orange);
  color: var(--primary-orange);
}

.appointment-table .appointment-table-head .table-head-th {
  border-bottom: 0;
}

.appointment-table
  .appointment-table-head
  .table-head-date
  .MuiInput-underline:before,
.appointment-table
  .appointment-table-head
  .table-head-date
  .MuiInput-underline:after {
  border-bottom: 0;
}

.appointment-table .appointment-table-body .MuiTableCell-alignCenter {
  text-align: left;
}

.appointment-table .appointment-table-body .appointment-table-btn {
  font-size: 10px;
  line-height: 10px;
  margin-bottom: 6px;
  padding: 4px 12px;
  border-radius: 6px;
  min-width: auto;
}

.appointment-table
  .appointment-table-body
  .appointment-table-btn.MuiButton-outlinedPrimary {
  background-color: var(--primary-blue);
  color: var(--primary-white);
}

.appointment-table
  .appointment-table-body
  .appointment-table-btn.MuiButton-outlined {
  border: 1px solid var(--primary-blue);
  color: var(--primary-blue);
}

.appointment-table
  .appointment-table-body
  .appointment-table-btn.MuiButton-outlined.recommen-color {
  border: 1px solid var(--primary-blue);
  background-color: var(-primary-backgroud-light-blue);
  color: var(--primary-blue);
}

.appointment-table
  .appointment-table-body
  .appointment-table-btn.MuiButton-outlinedPrimary {
  color: var(--primary-white);
}

.appointment-table
  .appointment-table-body
  .appointment-table-btn.MuiButton-outlined.regular-color:not(.MuiButton-outlinedPrimary) {
  border: 1px solid var(--primary-green);
  background-color: var(--primary-backgroud-light-green);
  color: var(--primary-green);
}

.appointment-table
  .appointment-table-body
  .appointment-table-btn.MuiButton-outlined.overlap-color:not(.MuiButton-outlinedPrimary) {
  border: 1px solid var(--primary-orange);
  background-color: var(--primary-backgroud-light-orange);
  color: var(--primary-orange);
}

.appointment-table
  .appointment-table-body
  .appointment-table-btn.MuiButton-outlinedPrimary {
  background-color: #388ef2;
  color: var(--primary-white);
}

/* for secondary tab style */
.tab-section {
  position: relative;
}
.tab-section .tab-category-title {
  position: absolute;
  top: 0;
  margin-left: 40px;
  display: inline-block;
  height: 48px;
  line-height: 48px;
  font-weight: bold;
  width: 250px;
  text-align: left;
}
.tab-section .secondary-tab {
  padding: 0 24px;
  margin-left: 250px;
}

/* NewApp step 1 General Info*/
.newApp-stepper,
.newApp-paper {
  background-color: var(--secondary-backgroud-light-grey) !important;
}
.newApp-paper {
  box-shadow: none !important;
  padding: 16px 32px;
}
.newApp-form {
  background-color: var(--primary-white);
  padding-bottom: 1.5rem;
  border-radius: 10px;
}
.newApp-form .newapp-area {
  background-color: var(--primary-white);
  padding: 24px;
  border-radius: 10px 10px 0 0;
}
.newApp-form .newapp-area .step-title .MuiDivider-root {
  color: var(--primary-text-line-grey);
}
.newApp-form .newapp-area .step-title {
  color: var(--primary-text-dark-blue);
  font-weight: bolder;
  text-transform: uppercase;
  font-size: 2rem;
}
.newApp-form .newapp-area .step-title-desc {
  font-size: 1.5rem;
}
.newApp-form .newapp-area .group-item {
  margin: 24px 0 0 16px;
}
.newApp-form .newapp-area .group-item .group-item-title,
.newApp-form .newapp-area .group-item > .MuiInputLabel-root,
.newApp-form .newapp-area .paper-block-title {
  font-size: 16px;
  font-weight: bold;
  color: var(--primary-text-dark-blue);
}
.newApp-form .newapp-area .group-item > .MuiInputLabel-root {
  font-size: 24px;
}
.newApp-form .newapp-area .group-item label + .MuiInput-formControl {
  margin-top: 32px;
}
.newApp-form .newapp-area.step-first .group-item .group-item-title {
  margin-bottom: 0.5rem;
}
.newApp-form .newapp-area.step-forth .forth-paper {
  margin: 1.5rem;
  border: 1px solid var(--primary-blue);
  border-radius: 10px;
}
.newApp-form .newapp-area.step-forth .forth-paper .paper-block-title {
  height: 4rem;
  line-height: 4rem;
  background-color: var(--primary-backgroud-light-blue);
}
.newApp-form .newapp-area.step-forth .forth-paper .time-area {
  position: relative;
}
.newApp-form .newapp-area.step-forth .forth-paper .time-area .time-circle {
  position: absolute;
  display: block;
  top: -3rem;
  left: calc(50% - 70px);
  width: 128px;
  height: 128px;
  line-height: 128px;
  color: var(--primary-text-dark-blue);
  font-size: 1.5rem;
  font-weight: bolder;
  border: 1px solid var(--primary-circle-border-grey);
  box-shadow: 0px 0px 20px var(--primary-circle-border-grey);
  background-color: var(--primary-white) !important;
  border-radius: 68px;
}
.newApp-form
  .newapp-area.step-forth
  .forth-paper
  .time-area
  .time-circle
  .circle-text {
  border: 1px solid var(--primary-circle-border-grey);
  box-shadow: 0px 0px 20px var(--primary-circle-border-grey);
  width: 96px;
  height: 96px;
  line-height: 96px;
  margin: 16px;
  border-radius: 48px;
}
.newApp-form
  .newapp-area.step-forth
  .forth-paper
  .paper-block-card
  .card-image-area {
  padding: 16px 0 0 0;
}
.newApp-form
  .newapp-area.step-forth
  .forth-paper
  .paper-block-card
  .MuiAvatar-root {
  width: 50px;
  height: 50px;
  position: relative;
  top: 24px;
}
.newApp-form
  .newapp-area.step-forth
  .forth-paper
  .paper-block-card
  .MuiAvatar-root.patient-image {
  top: 12px;
}
.newApp-form .newapp-area.step-forth .forth-paper .paper-block-card .doc-desc {
  border-bottom: 1px solid var(--secondary-backgroud-light-grey);
}
.newApp-form
  .newapp-area.step-forth
  .forth-paper
  .paper-block-card
  .card-content
  td {
  padding: 24px 0;
  border: 0px;
}
.newApp-form
  .newapp-area.step-forth
  .forth-paper
  .paper-block-card
  .card-content
  td
  h6 {
  color: var(--primary-text-dark-blue);
  font-weight: bolder;
  font-size: 12px;
}
.newApp-form .newapp-area.step-forth .forth-paper .checkbox-area {
  margin-left: 24px;
}
.newApp-form .newapp-area.step-fifth .fifth-paper {
  margin: 24px 16px;
  border: none;
  width: 100%;
}
.newApp-form .newapp-area.step-fifth .fifth-paper .fifth-paper-section {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.newApp-form
  .newapp-area.step-fifth
  .fifth-paper
  .fifth-paper-section
  .left-section {
  width: calc(33% - 8px);
}
.newApp-form
  .newapp-area.step-fifth
  .fifth-paper
  .fifth-paper-section
  .right-section {
  width: calc(67% + 8px);
}
.newApp-form
  .newapp-area.step-fifth
  .fifth-paper
  .fifth-paper-section
  .fifth-paper-card {
  border: 2px solid var(--primary-circle-border-grey);
  box-shadow: none;
  border-radius: 10px;
  margin-right: 32px;
}
.newApp-form
  .newapp-area.step-fifth
  .fifth-paper
  .fifth-paper-section
  .fifth-paper-card
  .card-title
  .MuiTypography-h5 {
  color: var(--primary-text-dark-blue);
  font-weight: bolder;
  font-size: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--primary-text-line-grey);
}
.newApp-form
  .newapp-area.step-fifth
  .fifth-paper
  .fifth-paper-section
  .fifth-paper-card
  .card-input {
  background-color: var(--primary-input-background-grey);
  border: 1px solid var(--primary-circle-border-grey);
  border-bottom: 0;
}
.newApp-form
  .newapp-area.step-fifth
  .fifth-paper
  .fifth-paper-section
  .fifth-paper-card
  .card-input
  input {
  padding: 10px 14px;
}
.newApp-form
  .newapp-area.step-fifth
  .fifth-paper
  .fifth-paper-section
  .fifth-paper-card
  .card-textarea
  > .MuiInputBase-root {
  background-color: var(--primary-input-background-grey);
}
.newApp-form
  .newapp-area.step-fifth
  .fifth-paper
  .fifth-paper-section
  .fifth-paper-card
  .actions-section {
  margin-top: 100px;
  background-color: var(--primary-blue15);
}
.newApp-form
  .newapp-area.step-fifth
  .fifth-paper
  .fifth-paper-section
  .fifth-paper-card
  table
  tr {
  border-bottom: 1px solid var(--primary-circle-border-grey);
}
.newApp-form
  .newapp-area.step-fifth
  .fifth-paper
  .fifth-paper-section
  .fifth-paper-card
  table
  td {
  border-bottom: 0;
}

.newApp-form
  .newapp-area.step-fifth
  .fifth-paper
  .fifth-paper-section
  .fifth-paper-card
  table
  td.icon-name {
  display: flex;
  align-items: center;
  padding-top: 26px;
  font-weight: bold;
}
.newApp-form
  .newapp-area.step-fifth
  .fifth-paper
  .fifth-paper-section
  .fifth-paper-card
  table
  .icon-name
  svg,
.newApp-form
  .newapp-area.step-fifth
  .fifth-paper
  .fifth-paper-section
  .fifth-paper-card
  table
  .input-area
  svg {
  color: var(--primary-blue);
  margin-right: 8px;
}
.newApp-form
  .newapp-area.step-fifth
  .fifth-paper
  .fifth-paper-section
  .fifth-paper-card
  table
  td.input-area
  > div {
  border: 1px solid var(--primary-grey);
  padding: 0 0 0 24px;
  border-radius: 8px;
  color: var(--primary-date-time-text-grey);
  background-color: var(--primary-input-background-grey);
}
.newApp-form
  .newapp-area.step-fifth
  .fifth-paper
  .fifth-paper-section
  .fifth-paper-card
  table
  td.input-area
  > div
  input {
  color: var(--primary-date-time-text-grey);
}
.newApp-form
  .newapp-area.step-fifth
  .fifth-paper
  .fifth-paper-section
  .fifth-paper-card
  table
  td.input-area.add-area
  > div {
  color: var(--primary-date-time-text-grey);
  font-weight: bold;
  background-color: var(--primary-white);
  margin: 0;
}
.newApp-form
  .newapp-area.step-fifth
  .fifth-paper
  .fifth-paper-section
  .fifth-paper-card
  table
  td.input-area.add-area
  > div
  svg {
  color: var(--primary-blue);
}
.newApp-form
  .newapp-area.step-fifth
  .fifth-paper
  .fifth-paper-section
  .fifth-paper-card
  table
  td.input-area.add-area.type-select {
  min-width: 200px;
}
.newApp-form
  .newapp-area.step-fifth
  .fifth-paper
  .fifth-paper-section
  .fifth-paper-card
  table
  td.input-area.add-area.type-select
  > div {
  width: 100%;
}
.newApp-form
  .newapp-area.step-fifth
  .fifth-paper
  .fifth-paper-section
  .fifth-paper-card
  table
  td.add-btn
  button {
  font-weight: bold;
  border: 2px solid var(--primary-blue);
  color: var(--primary-blue);
}

.newApp-form
  .newapp-area.step-fifth
  .fifth-paper
  .fifth-paper-section
  .fifth-paper-card
  .actions-section
  tr,
.newApp-form
  .newapp-area.step-fifth
  .fifth-paper
  .fifth-paper-section
  .fifth-paper-card
  .actions-section
  td {
  border: 0;
  height: 72px;
}
.newApp-form
  .newapp-area.step-fifth
  .fifth-paper
  .fifth-paper-section
  .fifth-paper-card
  .actions-section
  td
  .selected-item
  > .MuiSelect-select.MuiSelect-select {
  padding-left: 32px;
}
.newApp-form
  .newapp-area.step-fifth
  .fifth-paper
  .fifth-paper-section
  .fifth-paper-card
  .actions-section
  td
  .selected-item
  > .MuiSelect-select.MuiSelect-select
  > svg {
  position: absolute;
  top: 4px;
  left: -16px;
}
li.icon-item {
  font-size: 16px;
}
li.icon-item svg {
  font-size: 20px;
  margin-right: 8px;
  color: var(--primary-blue);
}
.newApp-form .btnGroup-container {
  background-color: var(--primary-white);
  border-radius: 0 0 10px 10px;
  padding-left: calc(50% - 104px);
  margin-bottom: 2rem;
  display: flex;
}
.newApp-form .btnGroup-container button {
  display: inline-block;
}
.newApp-form button.MuiButtonBase-root.search-button {
  background-color: var(--secondary-backgroud-light-blue);
  color: var(--primary-white);
  border-color: var(--primary-white);
}
.newApp-form
  .btnGroup-container
  button.MuiButton-containedPrimary:not(.Mui-disabled) {
  background-color: var(--secondary-backgroud-light-blue);
  display: flex;
}
.newApp-form .btnGroup-container .secondBtn .leftArrow {
  display: none;
  height: 24px;
  margin-left: 8px;
}
.newApp-form .btnGroup-container .secondBtn .leftArrow-hide {
  display: none;
}
.newApp-form .btnGroup-container .secondBtn:hover .leftArrow {
  display: block;
}
.date-title {
  background-color: var(--primary-backgroud-light-blue);
  text-align: center;
  font-weight: bold;
  color: var(--primary-blue);
}

/* AppList style start */
.calendar-table-header {
  position: relative;
}
.calendar-table-header > svg {
  position: absolute;
  top: 0;
  width: 18px;
  margin-left: 2px;
}
/* AppList style end */
